import Rails from '@rails/ujs'

import '@hotwired/turbo-rails'
import Alpine from 'alpinejs'
import flashMessageApp from './src/flashMessageApp'
import { notifyNow } from './src/flashMessageApp'
import projectScopeApp from './src/projectScopeApp'
import modalApp from './src/modalApp'
import drawerApp from './src/drawerApp'
import tagsInputApp from './src/tagsInputApp'
import sidebarApp from './src/sidebarApp'

window.Tagify = require('@yaireo/tagify')
window.Alpine = Alpine
window.Rails = Rails

Alpine.data('flashMessageApp', flashMessageApp)
Alpine.data('notifyNow', notifyNow)
Alpine.data('projectScopeApp', projectScopeApp)
Alpine.data('modalApp', modalApp)
Alpine.data('drawerApp', drawerApp)
Alpine.data('tagsInputApp', tagsInputApp)
Alpine.data('sidebarApp', sidebarApp)
Alpine.start()
